import React from 'react';
import { useState, useEffect } from "react";
import { Link } from "react-scroll";

 function LinksOnHome(){
   const [navActive, setNavActive] = useState(false);
   const toggleNav = () => {
    setNavActive(!navActive);
  };

  function closeMenu() {
    setNavActive(false);
  };
  return( 
     
        <div className={`home-navbar--items ${navActive ? "active" : ""}`}>
        <ul>
          <li>
            <Link
              onClick={closeMenu}
              activeClass="navbar--active-content"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              to="heroSection"
              className="navbar--link firstlink" 
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              onClick={closeMenu}
              activeClass="navbar--active-content"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              to="MyPortfolio"
              className="navbar--link" 
            >
              Portfolio
            </Link>
          </li>
         
          <li>
            <Link
              onClick={closeMenu}
              activeClass="navbar--active-content"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              to="About"
              className="navbar--link" 
            >
              About
            </Link>
          </li>
        </ul>
      </div>
 
  );
  

}


export default LinksOnHome;
