import HomeSection from "../HomeSection";
import MyPortfolio from "../MyPortfolio";
import ContactMe from "../ContactMe";
import About from '../About';
import Footer from '../Footer';
export default function Home(){
  return (
      <>
       <HomeSection />    
       <MyPortfolio />
       <About />
       <ContactMe />
       <Footer />
     </>
  )
}
