import React from 'react';
import { useState, useEffect } from "react";
import { Link } from "react-scroll";
import LinksOnHome from "./LinksOnHome.js";
 function HomeSection(){
   const [navActive, setNavActive] = useState(false);
   const toggleNav = () => {
    setNavActive(!navActive);
  };

  function closeMenu() {
    setNavActive(false);
  };
  return( 
      <section id="homesection" className="homepage-section">
         <div  className="background-image" ></div>
         <div className="homeContent">
           <div className="home_name">Yan Zhu</div>
           <p> Hello, I am a Software Engineer from the San Francisco Bay Area</p>
           <LinksOnHome />
       <ul className="media-icons">
            <li>
              <a
                href="https://github.com/Lucky-Yandy"
                className="navbar--content"
              ><img src="./img/github.png" alt="img" className="footer--img" />
               
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/yanzhu-tech/"
                className="navbar--content"
              
              ><img src="./img/Linkedin.png" alt="img" className="footer--img" />
               
              </a>
            </li>
           
          </ul>
         </div>
         
         
         
      </section>
  
  );
  

}


export default HomeSection;
